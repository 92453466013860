.form-edit {
    &__header {
        margin-bottom: 20px;

        @include media-breakpoint-up(medium) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .btn--icon {
            padding: 0;

            &:hover,
            &:focus {
                background: transparent;
                border-color: transparent;

                .tooltip {
                    z-index: 2;
                    top: calc(100% + 10px);
                    left: 0;
                    display: block;
                    width: auto;
                    height: auto;
                    padding: 5px 8px;
                    clip: auto;
                    border-radius: 5px;
                    overflow: visible;
                    font-size: 14px;
                    white-space: nowrap;
                    background: color("primary", "base");
                    color: $white;
                }
            }
        }
    }

    &__header-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn {
            margin-left: 20px;

            &:hover,
            &:focus {
                .icon:before {
                    color: $white;
                }
            }
        }
    }

    &__fieldset {
        padding: 55px 60px;
        margin-bottom: 20px;
        border: none;
        border-radius: 20px;
        background: $white;
        min-inline-size: auto;
    }

    &__fieldset-grid {
        @include media-breakpoint-up(medium) {
            padding-right: 100px;
        }

        @include media-breakpoint-up(large) {
            padding-right: 150px;
        }
    }

    &__legend {
        margin-bottom: 30px;
    }

    &__grid {
        grid-gap: 35px;
    }

    &__other-part {
        margin-top: 35px;
        grid-gap: 35px;
    }
}
