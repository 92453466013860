.field-repeater {
    $self: &;

    &-row {
        display: flex;

        &-item {
            background-color: #eff1f8;
        }

        &__field {
            flex-grow: 2;
            position: relative;
        }

        &__border {
            border: 1px solid #eff1f8;
            border-radius: 20px;
        }

        &__actions {
            display: flex;
            align-items: flex-start;
            margin-left: 0.8rem;
            min-width: 3.2rem;

            .btn[class*=" icon-"] {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 2.4rem;
                margin-top: 0.5rem;
                width: 3.2rem;
                height: 3.2rem;
                border-radius: 100%;
            }

            .btn + .btn,
            .js-repeater-action + .js-repeater-action {
                margin-left: 0.8rem;
            }
        }

        &__text-add {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #3b5998;
        }

        &__btn-delete {
            width: 40px;
            height: 40px;
            position: absolute;
            right: 80px;
            top: 0;
            display: flex;
            flex-direction: row;
            padding: 8px;
            background: #ffffff;
            border: 1px solid #eff1f8;
            border-radius: 50px;
            transform: translateY(-50%);
            &:hover {
                background-color: #eff1f8;
                color: #3b5998;
            }
            &:focus {
                background-color: #ffffff;
                color: #3b5998;
            }
        }

        &__btn-add {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 7px 28px 7px 32px;
            gap: 5px;
            width: 150px;
            height: 44px;
            border-radius: 50px;
            background-color: #eff1f8;
            color: #3b5998;

            &__left {
                margin-left: 75%;
                transition: color 0.4s ease, background 0.4s ease, border 0.4s ease;
                background-color: #eff1f8;
                color: color("primary", "base");
                &:hover {
                    background-color: #3b5998;
                    color: #ffffff;
                }
                &:focus {
                    background-color: #ffffff;
                    color: #3b5998;
                }
            }
        }
    }
}
