.table {
    width: 100%;
    margin-bottom: 20px;
    border-spacing: 0;

    &__col-header {
        padding: 0;
        border-top: 0;
        border-bottom: 2px solid color("primary", "base");
        background: $white;
        font-weight: 600;
    }

    &__sort-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0 16px 25px;
        background: transparent;
        color: color("text", "base");
        font-weight: 600;
        transition: color 0.5s ease, background 0.5s ease;

        .icon {
            font-size: 18px;
        }

        &:hover {
            background: color("primary", "base");
            color: $white;

            .icon:before {
                color: $white;
            }
        }
    }

    &__row {
        &:nth-child(even) {
            background: rgba(color("secondary", "light"), 0.4);
        }

        &--header {
            font-weight: bold;

            td {
                padding-left: 0;
            }
        }
    }

    &__col {
        padding: 16px 0 16px 25px;
    }

    &__link-icon,
    &__btn-icon {
        position: relative;
        padding: 0 5px;
        font-size: 24px;

        &:hover,
        &:focus {
            .tooltip {
                z-index: 2;
                top: calc(100% + 10px);
                right: 0;
                display: block;
                width: auto;
                height: auto;
                padding: 5px 8px;
                clip: auto;
                border-radius: 5px;
                overflow: visible;
                font-size: 14px;
                white-space: nowrap;
                background: color("primary", "base");
                color: $white;
            }
        }
    }

    button.table__btn-icon {
        background: transparent;
    }
}

.table-overflow-wrapper {
    @include media-breakpoint-down(small) {
        overflow-x: scroll;
    }
}
